'use client'

import { LogOutIcon } from 'lucide-react'
import { MenuItem } from '../Menu'

export default function LogoutMenuItem () {
  return (
    <MenuItem href='/logout'>
      <LogOutIcon size={16} />
      Logout
    </MenuItem>
  )
}
