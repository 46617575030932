'use client'

import { Row, Split, Stack } from '@christiankaindl/lyts'
import { ComboboxItem, ComboboxPopover } from '@ariakit/react'
import Link from 'next/link'
import { forwardRef, memo } from 'react'
import SearchProvider, { useSearch } from './SearchProvider'
import LawIcon from '@/public/assets/law.icon.svg'
import { ArrowRight } from 'lucide-react'
import * as styles from './Search.css'
import { getUrl } from '@/lib/utils'
import { ResultItem } from './Search'
import Text from '@/components/Text/Text'

const SearchPopover = memo(function SearchPopover ({ query }: { query: string }) {
  return (
    <ComboboxPopover flip={false} sameWidth gutter={8} className={styles.suggestionsContainer}>
      <SearchPopoverContent query={query} />
    </ComboboxPopover>
  )
})
export default SearchPopover

export const SearchPopoverContent = memo(function SearchPopoverContent ({ query }: { query: string }) {
  return (
    <>
      <SearchProvider sortBy={['_text_match']} id='rich-results' query={query} params={{ q: query, group_by: '', per_page: 1, facet_by: '', query_by: 'citation, title', filter_by: 'documentType:law || documentType:clause', exclude_fields: 'content', query_by_weights: '150,1', text_match_type: 'max_weight' }}>
        {query && (
          <RichResultIndex />
        )}
      </SearchProvider>

      <SearchProvider sortBy={['_text_match']} id='query-suggestions' query={query ?? '*'} indexName='query_suggestions' params={{ per_page: 7, query_by: 'q', facet_by: '', filter_by: '', group_by: '' }}>
        <QuerySuggestions query={query} />
      </SearchProvider>
    </>
  )
})

function QuerySuggestions ({ query }: { query: string }) {
  const { results } = useSearch()

  return (
    <>
      {results?.hits?.map((hit) => {
        if (hit === undefined) return null
        return (
          // @ts-expect-error need customizable useSearch() types
          <ComboboxItem hideOnClick render={<ResultItem hit={hit.document} />} key={hit.document.id} value={hit.document.q} />
        )
      })}
      {results?.hits?.length === 0 && query && (
        <ComboboxItem hideOnClick render={<ResultItem hit={{ q: `Suche nach ${query}` }} query={query} />} value={query} />
      )}
    </>
  )
}

function RichResultIndex () {
  const { results } = useSearch()

  if (!results?.hits?.[0]?.document.abbreviation) {
    return null
  }

  return (
      <ComboboxItem hideOnClick render={<RichResult hit={results?.hits?.[0]?.document} />} />
  )
}

type RichResultProps = React.HTMLProps<HTMLDivElement> & {
  hit: any
  onSelect?: () => void
}
export const RichResult = forwardRef<HTMLDivElement, RichResultProps>(function RichResult ({ hit, onSelect, ...props }, ref) {
  return (
    // @ts-expect-error Wrong ref type in LYTS?
    <Row gap='6px' asChild ref={ref} className={styles.resultItem} {...props}>
      <Link
        href={getUrl(hit.lawType, { norm: hit.abbreviation ?? hit.normId, abbrNr: hit.abbrNr, enumeration: hit.documentType === 'clause' ? hit.enumeration : undefined })}
        onClick={() => {
          onSelect?.()
        }}
      >
        <div
          style={{
            height: 32,
            width: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(228, 177, 95, 0.2)',
            borderRadius: 99,
            marginLeft: -9,
            color: '#A48757',
            flexShrink: 0,
          }}
        >
          {hit.documentType === 'law' && (
            <LawIcon width={20} />
          )}
          {hit.documentType === 'clause' && (
            <Text size='large' color='currentColor' weight={600}>§</Text>
          )}
        </div>

        {hit.documentType === 'law' && (
          <Stack gap={0} xAlign='start'>
            <Text>{hit.abbreviation} &middot; {hit.title}</Text>
            <Text hyphens style={{ marginTop: -3 }} maxLines={1} color='secondary' size='small'>{hit.lawType}</Text>
          </Stack>
        )}
        {hit.documentType === 'clause' && (
          <Stack gap={0} xAlign='start'>
            <Text maxLines={1} hyphens>{hit.enumeration}{hit.title && <>&ensp;{hit.title as string}</>}</Text>
            <Text style={{ marginTop: -3 }} maxLines={1} color='tertiary' size='small'>{hit.abbreviation} &middot; {hit.lawTitle}</Text>
          </Stack>
        )}
        <Split />
        <ArrowRight size={16} style={{ opacity: 0.5 }} />
      </Link>
    </Row>
  )
})
