'use client'

import { forwardRef } from 'react'
import * as styles from './Menu.css'
import type { Props as ButtonProps } from '@/components/Button/Button'
import * as AriakitMenu from '@ariakit/react/menu'
import Button from '@/components/Button/Button'

export const MenuList = forwardRef<HTMLDivElement, AriakitMenu.MenuProps>(function ({ children, ...props }, ref) {
  return (
    <AriakitMenu.Menu unmountOnHide portal {...props} className={styles.content} gutter={6} ref={ref}>
      {children}
    </AriakitMenu.Menu>
  )
})

export const MenuButton = forwardRef<HTMLDivElement, AriakitMenu.MenuButtonProps & ButtonProps>(function ({ children, ...props }, ref) {
  return (
    <AriakitMenu.MenuButton render={<Button variant={props.variant ?? 'flat'} />} className={`${styles.trigger} ${props.className ?? ''}`} {...props} ref={ref}>
      {children}
    </AriakitMenu.MenuButton>
  )
})
export function Menu (props: AriakitMenu.MenuProviderProps) {
  return (
    <AriakitMenu.MenuProvider {...props} />
  )
}

export default Menu
