'use client'

import { Row, Stack } from '@christiankaindl/lyts'
import { type FC, memo, useRef, useState } from 'react'
import { Input } from '../Search/Search'
import Button from '../Button/Button'
import { useRouter } from 'next/navigation'
import { writable } from 'svelte/store'
import { useStore } from '@/lib/store/store'
import { Overlay, OverlayContent } from '@/components/Overlay'
import { Search as SearchIcon, X } from 'lucide-react'
import { SearchPopoverContent } from '@/components/Search/SearchPopover'
import { Combobox, ComboboxPopover, ComboboxProvider, DialogDisclosure, DialogDismiss } from '@ariakit/react'

export const searchOverlayStore = writable({ isOpen: false })

export const MobileSearchOverlay: FC = memo(function SearchOverlay () {
  const { isOpen } = useStore(searchOverlayStore)
  const setIsOpen = (isOpen) => { searchOverlayStore.set({ isOpen }) }

  const inputRef = useRef<HTMLInputElement>(null)
  const [query, setQuery] = useState('')

  const router = useRouter()

  return (
    <Overlay open={isOpen} setOpen={(bool) => { setIsOpen(bool) }}>
      <DialogDisclosure
        render={(
          <Button
            size='large'
            icon={SearchIcon}
            variant='flat'
            title='Gesetze durchsuchen...'
          />
        )}
      />
      <OverlayContent title='Suche' header={false}>
        <Stack>
          <ComboboxProvider open placement='bottom'>
            <Row bleedRight={6} gap={0.75}>
              <Combobox
                value={query}
                render={(
                  <Input
                    value={query}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        router.push(`/suche?q=${query}`)
                        setIsOpen(false)
                      }
                    }}
                    onChange={(event) => {
                      setQuery(event.currentTarget.value)
                    }}
                    ref={inputRef}
                    style={{ flexGrow: 1 }}
                  />
                )}
              />
              <DialogDismiss render={<Button circular square size='small' />}>
                <X size={16} />
              </DialogDismiss>
            </Row>
            <ComboboxPopover flip={false} sameWidth gutter={6}>
              <Stack gap='1px' style={{ paddingLeft: 7 }}>
                <SearchPopoverContent query={query} />
              </Stack>
            </ComboboxPopover>
          </ComboboxProvider>
        </Stack>
      </OverlayContent>
    </Overlay>
  )
})

export default MobileSearchOverlay
