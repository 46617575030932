'use client'

import { useStore } from '@/lib/store/store'
import { writable } from 'svelte/store'
import Search from '../Search/Search'
import { Suspense } from 'react'

export const isGhostStore = writable(false)

export function NavBarSearch () {
  const ghost = useStore(isGhostStore)

  return (
    <Suspense>
      <Search enableShortcut={!ghost} id='navbar-search' style={{ width: '23em' }} inputProps={{ style: { paddingRight: 95 } }} />
    </Suspense>
  )
}
